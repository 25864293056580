import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Portfolio Review 2022`}</h1>
    <Breadcrumbs text="Articles" to="/articles/" mdxType="Breadcrumbs" />
    <h2>{`Overview`}</h2>
    <p>{`Overall, 2022 was a rough year for investors. The `}<em parentName="p">{`S&P 500`}</em>{` index closed the year at 3,839.50, down from 4,766.18 a year before, a decline of 19.4%. At the same time, the `}<em parentName="p">{`S&P Aggregate Bond`}</em>{` index fell by 12.0%, making bonds similarly unattractive.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d4783c3eae42118eeccf86b2a4135268/21b4d/assets.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwElEQVQoz3WS227cMAxE9///r88F0jbZdG3L1oWSKIqnsLcpekEJDERQwgwHo9sRIy8vX1nXQI+FUYRRG0MrZg13xek4jekds4xZxEbBRsJsx7wyrNF75xaWO18+fyJvr2h+w8sCskJ+4PHB3O/MvOFaoAZQgTn4KNcOsmNlexJKLaxx53VfeEsb7+lgTQUpCjZwGzA65IQmQWOGGJj7CnHD486UgtdCSombDbuU5oTaoJmRrPKWCkuphKLIMLbaeNTKJoNUJj4dqcpRJ7HDqb9tGzdVxd1xnyctuIODuiKzsV8kgoz+c1ZZJPN6VJI42idRC0GE3pWbmfF3Of+pnxendFEla+cwobvRTamtchPJlBIQWWl1ZdSDKRFGhlkuuGXwAi7P85xbpo2AzQwYPpXT7UW47xuPdeG+vLMs33hsd9b3OzV8p4XHlbKWjXgsyBEY9RRMF8aZbnxgZaWU/K/l09X5KQ5Vjt5ZpRNK48hCFCGUwhoy35dE3CoxCHvKhCSEEM4N5Vr1dwwd2PiA0npjnL0ZZoM2GrkKe40cPVOtUZo8/+HHhs+k/Rnyr/6Zwkk25/xj9nTjv4LyOa83PwDGHlpZ0iHCJwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "assets",
            "title": "assets",
            "src": "/static/d4783c3eae42118eeccf86b2a4135268/c1b63/assets.png",
            "srcSet": ["/static/d4783c3eae42118eeccf86b2a4135268/5a46d/assets.png 300w", "/static/d4783c3eae42118eeccf86b2a4135268/0a47e/assets.png 600w", "/static/d4783c3eae42118eeccf86b2a4135268/c1b63/assets.png 1200w", "/static/d4783c3eae42118eeccf86b2a4135268/21b4d/assets.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`The chart above paints a dark picture. Stocks and bonds both declined in unison. It is worth noting that long-term Treasuries dropped even faster than stocks, ending the year almost 30% down. In contrast, Treasury Bills with maturities of less than a year would have gained, but only very little. However, with the `}<a parentName="p" {...{
        "href": "https://fred.stlouisfed.org/series/DFF"
      }}>{`Federal Funds Rate`}</a>{` at 4.3%, T-Bills seem to be a viable option moving forward. Real-Estate lost about as much as stocks and bonds, while Gold was mostly flat for the year. This observation shows that hard assets are not the hedge against inflation many investors believe them to be. The only lucrative investment would have been to bet on the strong US dollar, up by 8.9% over the year. However, that rally ended in September, and the dollar has given up about 8.5% since.`}</p>
    <p>{`For tactical asset allocation, 2022 posed a significant challenge. Most strategies are designed to pick the best asset from a menu of stocks or ETFs but lack mechanisms to stay on the sidelines when there are no sound alternatives. For once, such a mechanism was not required for the past 40 years. But also because historically, staying on the sidelines typically meant missing out on the rebound, leading to lower long-term performance.`}</p>
    <p>{`Further, the market changed directions multiple times during the year, making it hard for algorithms to pick their side of the trade. Between January and June, the S&P 500 lost about 22.8%. However, between that low and mid-August, it rallied by 13.4%. Then, it reversed, to fall another 15.1% over the next two months, of which it recovered about 10% until the beginning of December, only to lose another 5% in the last month. These quick reversals require impeccable market timings to avoid whipsaws and underperformance. Unfortunately, this is especially difficult to achieve for momentum-based strategies.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/49c472465ac2424609ac0e0689e772c5/21b4d/buoy.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB6ElEQVQoz52SO2tUURSF76+xFLUQreys7CzsLfwZEgMqWFhYiJ0oWEVDDKIoIoioSBIyEw1BZ5KMUTTEuZn7PPe8Pzln7iRjSjcs9mPts9iHvZO1nSGzi+ucvdnh2NV1Ts70OT7Tizhxrcep2T6nr29FnLnR59ytTc7f3ubCnQEX737n8v0fXHnwk0v3BuS1IsFZgg1Ggu5uHtHbU2ztabb/jDEYKnZSzW5mSStLKR3aeqZteVCzXzQkWmvcP5xGUgO2xYQ0NIipPo/3Hhsety1lWZAYY2Li3CGprGGoSzJTkxvBvq4YmYrKyljT4VcenB8rCaeQVpNn2WRCj7ETOKwjinsHpZFURuEcMVfWkuqK2iiIfBPzXAuCVuKt4X/MUuNpAHlQk1KSpGXD2q+KF19yFjo5i52CxU7J0+iL6Cfxs27J888Fr9YrXm+UvNzIeN+v+bApePetRDSSZBgEf1fMd1MeLu1FPFoaMrecMbeS8WQlZ2G1YH414/FqeiD4ZqPi7deaj33Bpy0Re9KsIvHt2Rz9EKi42bD1w9i2uZnavmt5O15KLQRKaxqlaaRGKo1SJuYhbpSiFjJyoR5qpWzYb0rSpmDUVAilKEUddRJrxxP69gSCj2jvLF6gMbiw5qm+o3HgA/4C3t45J5ASUEIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "buoy",
            "title": "buoy",
            "src": "/static/49c472465ac2424609ac0e0689e772c5/c1b63/buoy.png",
            "srcSet": ["/static/49c472465ac2424609ac0e0689e772c5/5a46d/buoy.png 300w", "/static/49c472465ac2424609ac0e0689e772c5/0a47e/buoy.png 600w", "/static/49c472465ac2424609ac0e0689e772c5/c1b63/buoy.png 1200w", "/static/49c472465ac2424609ac0e0689e772c5/21b4d/buoy.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Strategy Performance`}</h2>
    <h3>{`Managed Bonds`}</h3>
    <p>{`In turbulent times, investors often look to bonds to dampen volatility and limit losses. However, in a year like 2022, this becomes an increasingly difficult proposition. At `}<em parentName="p">{`TuringTrader.com`}</em>{`, we recognized the need for a managed bond strategy early on and developed our `}<a parentName="p" {...{
        "href": "/portfolios/tt-buoy/"
      }}>{`Buoy strategy`}</a>{`. We first released the strategy in February, just before the bond meltdown unfolded. The strategy switched to T-Bills early on and held them for most of the year. In doing so, the strategy successfully avoided most of the aggregate bond market's drawdowns.`}</p>
    <p>{`We are pretty proud of this strategy and its results, so we immediately started to integrate it into many of our portfolios. Unlike other strategies, this one is not based on relative momentum. We wrote a `}<a parentName="p" {...{
        "href": "/articles/bonds-rising-yields/"
      }}>{`detailed background article`}</a>{` explaining its operation. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/20494b585f429b3e58050ed65f549d8f/21b4d/all-stars-tr-v4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACZUlEQVQoz32SSWgUURCGn3cRAxHBRD0oqAfBsyAqCKIEPRoNSHA7iEuUJIdoEMSLigtKDkEQT56ziYe4gAgKIs5kmenJTEyihkmIzvQyvb3u9z6ZbkiCBwt+6qeKKqrqL/Hy8zxrL7yj8fJX1l40OPzoO8efzrLuUo71VwwarxVo7p5iU2eBhqsGG6/n2dJt0NRpsLnLYGu3wYaOPAfuTeP5IcL1JS39RZp7Mxx8XOLj3B+s2OPcizK7b02zraeIODPBmvOTHH38g6auIuLEGKJ9AtE2jjg1jmgdZ+fNKRzXR2gVUTYjbD+moiwUkkAHuDjUzfIjvs35GOWQAI8P0xat/fM0dRXYd3eGOyNLtDz5yfaeIqZlI6SULJsGpXXif0ubRWniKi9JKULsyCPEQxKyYEbLZXOOzfuCjWWaiFBKolgjY5UgVjpBnXuRpBp6/PKr/A5qKAWujFgK0+lrMqTs2zixj6sdgiBAQMz/LU5H/ydmUiHAZqVepw2Liw4DGYvhrM3gN4f3Ro03uRpDGYfBjM1wxmEk6yT5oYzNqzGHt/kar/MVRvM2o5M1RnMOX2ZcgiBETC04HOsvsfdBniN9RZ59WuBV7g9tz+c49HCW/fdn2XGjxK7eEsf6Zthzu4Q4m0OcnEC0T9JwpYA4PUFjh0HVcuorq1WrqBV18BNofBQRMQEgqcUe2bLNx2KN7C+Xn5bLpx9VBsYqmHVRXNdDyogglIThap9yJ/CpejZu4CexKJLE2sfHIqgLEXvJHbVy0xvGcUzyKVqnQK9wnYqhonpBypO4IvmEuuo6eTWIIoVSir/6mwGMK0PFBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars tr v4",
            "title": "all stars tr v4",
            "src": "/static/20494b585f429b3e58050ed65f549d8f/c1b63/all-stars-tr-v4.png",
            "srcSet": ["/static/20494b585f429b3e58050ed65f549d8f/5a46d/all-stars-tr-v4.png 300w", "/static/20494b585f429b3e58050ed65f549d8f/0a47e/all-stars-tr-v4.png 600w", "/static/20494b585f429b3e58050ed65f549d8f/c1b63/all-stars-tr-v4.png 1200w", "/static/20494b585f429b3e58050ed65f549d8f/21b4d/all-stars-tr-v4.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Balanced Portfolios`}</h3>
    <p>{`Most investment portfolios are a combination of multiple asset classes and trading styles. Our `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr/"
      }}>{`All-Stars Total Return`}</a>{` is a good representation of such a middle-of-the-road portfolio. As 2022 unfolded, we adjusted the portfolio several adjustments to make it fit for the new circumstances. In April, we replaced `}<a parentName="p" {...{
        "href": "/portfolios/tt-vix-spritz/"
      }}>{`VIX Spritz`}</a>{` with an improved version. In October, we improved `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-mean-kitty"
      }}>{`Mean Kitty`}</a>{`. The common denominator of these changes was to reduce interest-rate risk and to improve the strategy's choices in risk-off scenarios. The chart above shows that the resulting strategy would have handled 2022 very well, ending the year with a drawdown of roughly 11% and significantly lower volatility than the 60/40 benchmark.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/83740ba5897cd6a14ce941f62d4fe0b8/21b4d/all-stars-tr-oos.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACYklEQVQoz5WSTUhUURiGD4otIwxalBkVFG1s0b5atYggIvqRwcqxNAuVSCwwaVFRuBACoVVQBlJEgYqbMkspkMZ0ZrzjOOPvMGpmzjgzzp079/eJe++Qqxa98HDeA+e8H+d8nxgIrbCj4TNbb/gobYxQ2hRiW+Mk5S1R9tyNUn4nSlmLy+4Cu5qn2Hk77FDWPMX2pjDH22eQFRWBZXCvf5Ej7RKHHoR49DGO5+Us4mIQ4ZEQlyRETcilOoTwFry3wNWQc+Zga5SNbA6h6zq2LAsSahYwAJ3Tz8OcebbAyc45RJV9WaK4YYyi+gDFtWGK60IU105SUhd2ClXcnyGZSiM0zQ10EgHTdNeMnkMlwxv/KkXXJ6h4LDESS1PVNYu4MkpJ3ZQTtuVmEFH/ncMPp/i9tm4HauimhWbYmOiG6+0VCxY3sviWEyRzqlNI1U1uvY8hqsYQXh/img/hCbKvTSKbUxCYBv8vi86ReTq+LOGP5+j4kODU0wUUJY+IJ2V6A2l6/Bn6/Bv0+jOOH5yUGQxn+RTOMhRxGY7IDEVkhqMyo/MKPxYUxmIKX6dlBiYz2K8VS+syDW9jnOiMcrQjStO7GJ4XcwivH1EjuZwNIM4FEJVjiPPjiAsTiCppk8ogB1qnWU9l3LHZlPnXBVZSSKtpXo+vcqxjmstd8/RMJGntW2Rvm4SoDiKq/Yi6cadJ+9vCrCVTCFnOkVc1FFVDVXXH5/NaIdzEsuxm5AvjZDr/9yudo3t0mW7fT4Yi67z6luBJf5ycoiAMwyhMjbUJFobpYk+Tqunohunsne7/Q6Zp8gdkcsSBwhgigAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars tr oos",
            "title": "all stars tr oos",
            "src": "/static/83740ba5897cd6a14ce941f62d4fe0b8/c1b63/all-stars-tr-oos.png",
            "srcSet": ["/static/83740ba5897cd6a14ce941f62d4fe0b8/5a46d/all-stars-tr-oos.png 300w", "/static/83740ba5897cd6a14ce941f62d4fe0b8/0a47e/all-stars-tr-oos.png 600w", "/static/83740ba5897cd6a14ce941f62d4fe0b8/c1b63/all-stars-tr-oos.png 1200w", "/static/83740ba5897cd6a14ce941f62d4fe0b8/21b4d/all-stars-tr-oos.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`However, this result suffers from hindsight bias, as it does not adequately reflect the availability of the updated portfolio revisions. To remove that bias, we simulated the strategy with out-of-sample results, switching to the updated releases only after they actually became available. The chart above shows that this portfolio is roughly 20% down for the year. Until October, it was tracking the 60/40 benchmark fairly closely. Since then, the portfolio missed out on the short-lived rebound, leading to the strategy trailing its benchmark by about 5%. This underperformance is an unfortunate but expected effect of markets changing direction multiple times.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e708e676294ffeaef40de54f212cdce0/21b4d/all-stars-tr-oos-spxtr.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACcElEQVQoz32SzUtUYRTG302UFH2gKzOrje0Moj8g2rcpXVRqMUZlgR9o4yZLEiSXriIi0BYtohZa9GFBfgWG1IzTnbkzNyu1sdSx0dF779y5c9/7i3tHJCJ64eE8z8vhcM55jhjVFilrGmXHxQl2XlUpDSoUN0cpu6axv12jLKixL1iI5e0FvrctTmmr6qOsLU5xk8qxnmnWDRMBDr3jSU71qVTdneHBhwU6X80iaiKIGgURiCLqo4gLUUStgji/wQMb8HidwqHrGrppIfJ2HpB4hVPuMuBpl9sjX7k3lqb5URJRPYU4F+VoT4zdrRGfb22IseVyjG0NKiIQo7JzmvRKBmHbeVwXXAm2dHxICTlMJBkm5la41L9A99APsm6Wh6EFSoIRRF200G0ghKh/z+FbGouptFfQxpEueUeSd1wfnjbtPMuWge5kMVjDQsdx8Lt/M53iSNcXKroiVPclqOxKUN4RwszmEEg/6z/PQmL99SdZcpfIoReUhMlvOrmchZhfMXgayTAQWuNZWGcwvMZAeI0hReelss7rqM67zyZjmsFw3GAkYTCi6bxQ0wwndN7GDUY1HSVp4q1P/Fw1CA7OUnVf48SdaW48n6PxyQxFjRFKmhMUXVERJ6cQVRFE7RTidBhRHUGcVQo488k37WC7xsrqOgL3z5HtTTa7nuG7kWF8Jk3L4yTdQ/NMJlfpn1zkeK/GniaVXS0KBzoUtjeHqbipklpOIwzTxMrZPuxc3o9ZywbfbRtbZpH+rnIb5yWxHJOJ74t8nP+Ft7JY0mAknsKyLIRTsA7XdTfhOSk3Ndi2g7Phfl56N/Zv+7z83+Qn06mUf46sAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars tr oos spxtr",
            "title": "all stars tr oos spxtr",
            "src": "/static/e708e676294ffeaef40de54f212cdce0/c1b63/all-stars-tr-oos-spxtr.png",
            "srcSet": ["/static/e708e676294ffeaef40de54f212cdce0/5a46d/all-stars-tr-oos-spxtr.png 300w", "/static/e708e676294ffeaef40de54f212cdce0/0a47e/all-stars-tr-oos-spxtr.png 600w", "/static/e708e676294ffeaef40de54f212cdce0/c1b63/all-stars-tr-oos-spxtr.png 1200w", "/static/e708e676294ffeaef40de54f212cdce0/21b4d/all-stars-tr-oos-spxtr.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`While this result might seem disappointing to some, we'd like to offer a different perspective. From a 10,000ft altitude, `}<em parentName="p">{`All-Stars Total Return`}</em>{` can provide long-term results beating the `}<em parentName="p">{`S&P 500`}</em>{`. Therefore, it is helpful to also compare the portfolio to the `}<em parentName="p">{`S&P 500 Total Return`}</em>{` index. It becomes apparent that the portfolio's out-of-sample performance is a tangible improvement over participating in the broad stock market. Even though the year's final result might not reflect that, the portfolio's volatility is about half that of the stock market index. And the portfolio achieved this result while delivering results within striking distance of both benchmarks. In other words, the portfolio is a success because its risk-adjusted metrics are still considerably better than their passive cousins', increasing the probability of investors reaching their financial goals.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/02b1fc3659e0a04e03f33b1fcb0584d0/21b4d/all-stars-stocks-v3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACVElEQVQoz32SzUtUYRTGL/Uv9AfUImhfy/6BFrURdSHkIkYKWtQuyEUoRhCt+jCRgaZm06IPEw2RDKIgInHGr5nUsOZLZ/Q6c7/v+973vb+YO2pR0AMP57znvHAOz3OMiVyN83fn6b2/xuVnVV7M77Fc8bmardL1qETvWIWesTI9j8v0jZfpT5dIZSqknpRJZcoMZCr0p8sMvqwRhAJjo+FycniJE7fy3J4yMaWLpV2mF10evt/jwZzJlWyNi+kKQ5M7nB7exLiwgNGziNHdZj6Jx298w3J9DLTi808bU3jU5C4SgatCfGz+RUSu5pD5ZHHzVZ3U0yr3ZkzO3vnBqcF1bNvGkFIefg+0QGqV5A1hUQubycZocCOBKVy82APkH0M0ufoeH9YdXLuFIaREqhipNFpDHMfEMYRKsSscbBnQEHZCpWO8SLIV2Igoph46VH0LPw6xtUUQhBjEiv9DAOFfNUWLtjwuEB9uKkTYNsVhIm/xJmczu+zxvSEomZKpvMPrhU59MuckcWLBZnrJYXbVYXLFZK7oMFfweFdw+brp0ZbPKG45nBtdoyu9xshMldUdi+VGi6G3Va4/307YN16le7RCKlvmzMgGRwcKGJcKGP0rHBlYxehd5Ni1Ik3LwUgUP4Tk99vfZ7hfE0m/KT0KuzbF7YAvJZuVhkV2vs7YxzpWq4nheT5RFBEKiRQRUka0jQpFhB8KrMBjx7OwA58wjFBRhNQBtmoRxg7EIhmotdfRUCmVuNpxd5904oHeWiX2J/nBFWgdE2uSy2i7H0U66f0CfEgT0m15S+cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars stocks v3",
            "title": "all stars stocks v3",
            "src": "/static/02b1fc3659e0a04e03f33b1fcb0584d0/c1b63/all-stars-stocks-v3.png",
            "srcSet": ["/static/02b1fc3659e0a04e03f33b1fcb0584d0/5a46d/all-stars-stocks-v3.png 300w", "/static/02b1fc3659e0a04e03f33b1fcb0584d0/0a47e/all-stars-stocks-v3.png 600w", "/static/02b1fc3659e0a04e03f33b1fcb0584d0/c1b63/all-stars-stocks-v3.png 1200w", "/static/02b1fc3659e0a04e03f33b1fcb0584d0/21b4d/all-stars-stocks-v3.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Aggressive Portfolios`}</h3>
    <p>{`Like `}<em parentName="p">{`All-Stars Total Return`}</em>{`, we updated our aggressive portfolio, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-stocks/"
      }}>{`All-Stars Stocks`}</a>{`, twice in 2022. In April, we updated `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose"
      }}>{`Stocks on the Loose`}</a>{`, and in October, we updated `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{`. Both updates aimed at reducing interest-rate risk and improving asset picks during risk-off periods. The resulting backtest shows how the portfolio added tremendous value by reducing the market's downside. The portfolio ended the year 13.5% down and beat its benchmark by 5.1% despite missing out on the October-November rally.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/498b0df9d7d9359c3c8f418da42af73f/21b4d/all-stars-stocks-oos.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACdElEQVQoz32Sy0uUURjGD4RE/0HQxkWrCKOgoMiIiKAWLSSIwNKkrAjbZkULxfBCIi2iRREVuNJFKVYkKVGUIGJzc0bTxkvT5Mx848x89/Od7/vFjHahRQ/8eB/ecxbnfZ8jBqdS1Nz4xJ7bEY7eTdI/kWMxa3O4J8n+ziQn7i1xqCfJkd5FaruTFQ52LfymtnuBfR3z1D9cwrJdxFLOoqYrys7OMH2jOTRpUPIN+kY1nn0s0D6cQZycRpwKIc7HEI0xxLkooiG6XhujiDMRqltnKRkWIvA9plYMSp5NSuZQSCzlYlCgrK85m8bHKS4+TfNhMc/ZJ0lEfYyqyzNsvjJD1aU4ommG3W3zaGtFhJSSX3J9D+mris+6JVJOnpIyN049AhzSVonWoRU2NccRDTG2tIQRzZNsvzXLajaPcKVEqgCpfJQPfhAQBOAoRV6aFKXNd7tI2i7iyAAfRU7qTCYtBiM/CGU0bg6lOHY/hpIugkDxf7mA809P4aAB1l89H+W5iPmMzotQkaHPJd5ETb6sOqzkJSMhnefTRV6GdcYTJmNxg1cRnbdxg/dzJiMzGmMJnXezJuOzBpNJk/K0IpHWOf5gjrpHc3S8ThFeLRDLFrg2sEz7cJar/WlEfRTRFGPb9TjiQgRRF0KcjmwQrvyA6utz5As6ovzUPyoHFGx4q4IZWHxOGUx+K1CQFhPLa7QMLLH3zgI72hIc6E2wtTXMro4EWn4NYZoWnufhuBLpekhZRuK6HqbjYrgWjtIre/SVquyv7DN2noxZJAhcVjSbqUUNWQ5FKVVJNaikuwHr9Zc8qVBqvaf8AN//M8hf1/D9gJ8bbPEzx5m1JgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars stocks oos",
            "title": "all stars stocks oos",
            "src": "/static/498b0df9d7d9359c3c8f418da42af73f/c1b63/all-stars-stocks-oos.png",
            "srcSet": ["/static/498b0df9d7d9359c3c8f418da42af73f/5a46d/all-stars-stocks-oos.png 300w", "/static/498b0df9d7d9359c3c8f418da42af73f/0a47e/all-stars-stocks-oos.png 600w", "/static/498b0df9d7d9359c3c8f418da42af73f/c1b63/all-stars-stocks-oos.png 1200w", "/static/498b0df9d7d9359c3c8f418da42af73f/21b4d/all-stars-stocks-oos.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To remove any hindsight bias from the backtest, we ran an out-of-sample simulation of the portfolio. Again, we are very pleased with the result. The portfolio is down about 19.6% for the year, right on par with the `}<em parentName="p">{`S&P 500 Total Return`}</em>{`. However, the portfolio had only about 14.1% volatility, while the benchmark's volatility was approximately 23.3%. This substantial reduction in risk also shows in the portfolio's underwater chart, which is substantially shallower than its benchmark.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/71b77a7c7ded7b1a4f9b645e5ffb0831/21b4d/all-stars-lev-v4.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbUlEQVQoz32Sz0tUURTHbxHVsjYlVOpIuGgR/RGt2pZIZZYTogNFWTE6KQYFLaKtEbQoiUKKFqJFhJZZSP7IcebNL2cmzXLEUWfUN/Nm3rxfn3hvoISoc/lwzzkX7j3nfK94Nr7I3tYRKtumcLUnONQeZv/1CJXeGK6OODW+BC5f3MH2q9tjVHn/YMcV16Icv/+NvFJErMkqR+5K7Lg6gaj34xtcoPfrEuJiGHEhjHCHy77N+S0595azcyFqO+PkCyrCNHT6ptZ5OZ3hUn+MTEEFLBr7ooimEKI5iGgMIc6EcHVL7PNKbG+OsssTYWdrhN2eKMId4eitJNn1TYSu66iaRdlMZ9mWLuZ4EV5kOJlhwK/waCzNxM8NGp4mEacnEI3lyoTbj2ge59idBOnVLKKkaZiWhWaY6Ib1m5JhoKNiUqKATJG889CX+Tw3+r/T9DjFiYcx6p7MsqctxIHOaZSiigCD/5nlXKr+ldXJAAUnmpxTufw8haaVEIl0jkFJ5q2UYyisMBCQ6Z+RGYoojMbzfIorfI4XnH0kpvAhlncYDGcYjuac3MfZPONzCvb4RCglU3vbLjmAaPHT0jdP95sfbPMEECcDiDqbIOJUENEwgzg7g6iXHJHKSIj6IId9CbIbObtlk82igazqJLM5px3b/MtZHoyl6J1cpmd0hXvDS7yOZLjyaoGDN0NUd8Sp8Iap6pIQnmlquiKsrmURilLAMnXs74NlYotUVDWw7NnaqBiOIKXyLzB0luU8oZVVoitZltYV3kkb9LxfpFQqIQzDcFR2MC0sy3KqtH3DtHfQNAPDKMfl838IaFn8Aj1ix1W48McKAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars lev v4",
            "title": "all stars lev v4",
            "src": "/static/71b77a7c7ded7b1a4f9b645e5ffb0831/c1b63/all-stars-lev-v4.png",
            "srcSet": ["/static/71b77a7c7ded7b1a4f9b645e5ffb0831/5a46d/all-stars-lev-v4.png 300w", "/static/71b77a7c7ded7b1a4f9b645e5ffb0831/0a47e/all-stars-lev-v4.png 600w", "/static/71b77a7c7ded7b1a4f9b645e5ffb0831/c1b63/all-stars-lev-v4.png 1200w", "/static/71b77a7c7ded7b1a4f9b645e5ffb0831/21b4d/all-stars-lev-v4.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h3>{`Leveraged Portfolios`}</h3>
    <p>{`Like `}<em parentName="p">{`All-Stars Total Return`}</em>{`, we updated our leveraged portfolio, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-lev/"
      }}>{`All-Stars Leveraged`}</a>{`, twice last year. In April, we updated `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2/"
      }}>{`Mach-2`}</a>{` and `}<a parentName="p" {...{
        "href": "/portfolios/tt-stocks-on-the-loose/"
      }}>{`Stocks on the Loose`}</a>{`; in October, we updated `}<a parentName="p" {...{
        "href": "/portfolios/tt-round-robin/"
      }}>{`Round Robin`}</a>{` and made further improvements to `}<a parentName="p" {...{
        "href": "/portfolios/tt-mach-2/"
      }}>{`Mach-2`}</a>{`. Again, the updates aimed to reduce interest-rate risk and improve asset selection in risk-off periods. The resulting backtest shows how the portfolio  successfully protected the downside, most prominently in July and September. However, it also missed out on the market's rebound in October and November, ending the year down about 22.6%.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1fba60ee08102b48a03997377dd1cd88/21b4d/all-stars-lev-oos.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACY0lEQVQoz42Sy0uUYRTGTyS1c1ORmEkgVERY/0BE+3YVBZMXvDQurChFRUKiyAoKwkWLcNmiZRqWWGFeEEydceZzbp+XNJMGnUln/Gac+W6/+GaIMgl64OGc88J7znve55F+JcqBxkH23xjj4K0Ix+8GONSqUNykcqRtltJWlZKWPEtbZjncMktxc5jiplCOJc1h9t0McfbxHJtaGsEyqX75haL2aaR+nBdjUZ6PfkeqPiMuBan0I3VBpDaI1HiRWh9SE0JqA0hNIH9eGeBou0piM4UYhoGWsZmL6vT448Q0HQd1r+Y40zXDuWcRxOVHqjyc7gxR1OZH3B72usMUuAPsaZxGGiYpvxcm9mMD0XWDv2HZNpZlAyYL65s09S7Q8XaZZNri6UAMqZ9Crk2y6/oEBY3OqxXKHwRZjcWdhjqGaaMbFlnDyuW/aJpgWOaOgT3TCU52KuxuUPIrX1Eoa1NJ5v7Q3nnhfzG/mmVlXefRuzXOdy2ylckgSzGNHt8GfUqSfkWjT0nQ60vwMZjiUzjFYFhjRHWYr4ci+TisaowvpJla3GJETfM+kMTQdWQpnuJi9zwn7gcobPLR8nqZtp5lpG4KqfAi1T7kgg+55EUqPYjLg1yeRlwzSIWCVPmQq16O3VHZSGrbV05mfgsUiGpMLCcYUOO09n6lo+8bw3NJnnyIcqozROFtP9LgQdzeXMOyjiBr8XUklUqTdYQxdGzTyOVbGcc61h80ttXZrI7/W4KhcILRSJLuoRgP36zgCCymaeIYxHasYtu56MC07Bwd92SyBrpp5WrDsv8hkY1tWfwEa6TKhDjKCR8AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars lev oos",
            "title": "all stars lev oos",
            "src": "/static/1fba60ee08102b48a03997377dd1cd88/c1b63/all-stars-lev-oos.png",
            "srcSet": ["/static/1fba60ee08102b48a03997377dd1cd88/5a46d/all-stars-lev-oos.png 300w", "/static/1fba60ee08102b48a03997377dd1cd88/0a47e/all-stars-lev-oos.png 600w", "/static/1fba60ee08102b48a03997377dd1cd88/c1b63/all-stars-lev-oos.png 1200w", "/static/1fba60ee08102b48a03997377dd1cd88/21b4d/all-stars-lev-oos.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As before, we ran an out-of-sample test to remove the hindsight bias. To do so, we switched the portfolio revisions when the updated strategies became available. In this test, the portfolio is down 31.0% over the year, compared to the S&P 500's 18.9%. This result seems disappointing at first, but again, some perspective helps. For most of the year, the portfolio shows a similar downside as the S&P 500. This is a good result, given that the portfolio is leveraged up by 33%, allowing it to outperform but also potentially adding a lot of risk.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f2e24ffe9467d8a853bed4f29151f706/21b4d/all-stars-lev-oos-spxtr133.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbUlEQVQoz32Sy0tVURSHd2RE0KwiM4tAqHBg/0HRvEkggZhmpVjeMErTwLKoyEJs0LiIJk4Eo0ApLF8VGD7v9b69+SozvQ/v45z7OK8v9j0GTuoHH3utPVhr7/Vb4r17lX2OUfY2fKPwhp+SNi8Hmr0cbA5y5HaQw61BiltsZHyoJUhRs5+iJl+e4mY/e677OPU0REpJI7AMarpD7L8ziaia4dnwCo8/LSMqnIjKWUSVG1Hrsal2I2o8iMsexKVNNu+PtgVIpFSErusoOZ3vkTSvnUusKVnA4uwrD6e7FjjZFbILV7o50eGhsNWNuOhl51UvBfVedtT78nnZvRCRWByhaTpbZVqmrEdcVwmbMeY3UrS/idDUu0w0m6Zz6AeidhJR42VbnZeCBieiYZyyB0HWwzFZUEM3LTTdJKdb6IZNzjBIaVlSRgaFBDnUfEMLk+7p3xxvn2N74wTCMY6onKbk/gzJvzP8v7RNtspi1VxnNrzBr4TGk/4oZ54vkslmEIsRhbfOBH2uFB/cCn2uJO9mkgz5VIYDCsN+lS9BldGgHY8E7PsBf5yxeZXJxQyf51QGPEl0TUMsRRXKX4YofeRmV6MTR88ijp4FRPWUbYZ0ttxlc96JqJhBnJOx20ZuQYWLY20h4kll65dNwpl0/jtSYz+jDM6H6fNFuNW7ws3eZYZCMToHVyh96GX3NR+izom4MoW4MEHJXR/h6AZCVdPkNB1dum0YSNczWTkz2UhuQBYTBcjlm0pSuTSj82t8DKzzNRTnxUiYjv5lpMHCMIz8myzLwrSs/ClfaZg2pgWaZuSdl7nciH/JMk3+AE1fyCoXMEbBAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "all stars lev oos spxtr133",
            "title": "all stars lev oos spxtr133",
            "src": "/static/f2e24ffe9467d8a853bed4f29151f706/c1b63/all-stars-lev-oos-spxtr133.png",
            "srcSet": ["/static/f2e24ffe9467d8a853bed4f29151f706/5a46d/all-stars-lev-oos-spxtr133.png 300w", "/static/f2e24ffe9467d8a853bed4f29151f706/0a47e/all-stars-lev-oos-spxtr133.png 600w", "/static/f2e24ffe9467d8a853bed4f29151f706/c1b63/all-stars-lev-oos-spxtr133.png 1200w", "/static/f2e24ffe9467d8a853bed4f29151f706/21b4d/all-stars-lev-oos-spxtr133.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`To better illustrate this view, we chart the portfolio against a benchmark leveraged by the same amount. It becomes evident that the portfolio added tremendous value throughout the year. The portfolio did a great job protecting the downside in June and September, and our only real complaint would be that it missed out on the October/ November rebound. The 133% benchmark closed the year 25.1% down, which puts the portfolio in striking distance. The metrics support our positive opinion: the portfolio had an annualized volatility of 17.9%, compared to the S&P's volatility of 23.3% and the leveraged benchmark's volatility of 31.02%. In summary, we consider `}<em parentName="p">{`All-Stars Leveraged`}</em>{` a success, regardless of its negative returns.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/99620b2269207728cc03c01ca5a3879d/21b4d/live-vs-backtest.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.333333333333336%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACbklEQVQoz5WR20tUURSHF5r6lBDmQ14gMKin/AeCpLfew1TSdCyVCsS8IGFUD0IFUfjQQ1APIUVEFGEqCIkEFqEzzplz5uYlo9J0Rh2dq3MuX5wzBQW+9IOPtddv7702ay95OfuNA5c+Ut4doqInRGmXn0NXg1T0BCnrCVDZF6KiL0xlX5hy2+sO/IN9rqQzQM3dRVLpXcQ0dU4OBRHXLOLyUNKrkHfZg5xXkVY/0qIiLg1x2bmWw/UXFzSkSeXoQJh4Mo2Aged7nDfKBo+mf6Ktppha2Ka4x420uynuUtjX5qeoI+AUyLvop7DDT36bRn6bn4IOv+Mfv7nAZmwHyWaz7KWJYIwX7g0Gx1eR2jmk2UvlgI+iKwrSpFHYHqCg3S4eQFo0qm8tENnYyhXMGha7uklGN8n+jn+krO5w4oGfs4+XiCayPJ+NcPi6D2n0IOcUpHkOaVKo6g+TcFq2DP5XhmXwdGaNOxOrjPhi3Jtc4fTDIMlUBllcj/PWu82YL864L86Id4d3SpypUJIP4aQTJ4MJ3gcTTIUSztr23ctpZpdTuL+mmV5IMapukc5kkOVoglNDYcr6VUp7VaoHAxy5oSENbqTWjdTNIfU+pF5BzniRWgVpsFtWczSpSJ3CsYF5trbj9pRz/7VrGOxk7AFZDq+8UZ7NRLk9scLBbo2qgQD1T5aouT/P/k4VafHmaPUijV6qrs0T3YwhiWQKXdcxdB3L0LGHZOe5hyywdBYjMVa2ko6n67v4fmzz2hNh+NM6Y74Nhj+vMTj6hWQqjRiGgWXfsyxMy3KijWFa6Ibl7GHlutDN3/mesjBNk1+57rLESM6wyQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "live vs backtest",
            "title": "live vs backtest",
            "src": "/static/99620b2269207728cc03c01ca5a3879d/c1b63/live-vs-backtest.png",
            "srcSet": ["/static/99620b2269207728cc03c01ca5a3879d/5a46d/live-vs-backtest.png 300w", "/static/99620b2269207728cc03c01ca5a3879d/0a47e/live-vs-backtest.png 600w", "/static/99620b2269207728cc03c01ca5a3879d/c1b63/live-vs-backtest.png 1200w", "/static/99620b2269207728cc03c01ca5a3879d/21b4d/live-vs-backtest.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Live Trading vs. Backtests`}</h2>
    <p>{`Of course, all these results are backtested and, therefore, hypothetical. Consequently, it is essential to confirm that these backtests represent results investors can achieve in their brokerage accounts. For this test, we use an account funded with several hundred thousand dollars that we traded throughout 2022. This account changed composition several times a year, replicating our advancements in research. Namely, we held `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr?v=2"
      }}>{`All-Stars Total Return v2`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-tr?v=3"
      }}>{`All-Stars Total Return v3`}</a>{`, `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl?v=1"
      }}>{`All-Stars XL v1`}</a>{`, and `}<a parentName="p" {...{
        "href": "/portfolios/tt-all-stars-xl?v=2"
      }}>{`All-Stars XL v2`}</a>{` in the account.`}</p>
    <p>{`The chart above shows how both curves track very closely. This proves that our assumptions regarding fees, commissions, and slippage are realistic and that investors can replicate our results if following the asset allocations promptly. The chart shows that the deviation between backtests and account values might fluctuate slightly, but these differences tend to even out. At the end of the year, we observe a discrepancy of less than 1%. This result, matches the observations we made in our `}<a parentName="p" {...{
        "href": "/articles/live-trading-vs-backtests/"
      }}>{`2021 analysis`}</a>{`.`}</p>
    <h2>{`Summary`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See Also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/live-trading-vs-backtests/"
          }}>{`Live trading vs. backtesting`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/review-2021/"
          }}>{`Review 2021`}</a></li>
      </ul>
    </Aside>
    <p>{`During the year, we made the following observations:`}</p>
    <ul>
      <li parentName="ul">{`Our Buoy strategy has done a tremendous job of managing bond maturities and far outperformed its benchmark.`}</li>
      <li parentName="ul">{`Most portfolios yielded negative returns, which are in line with the chosen benchmarks. The observed underperformance was mainly caused by missing out on the short-lived rally in October and November and, therefore, should not be considered a systematic flaw.`}</li>
      <li parentName="ul">{`Our timely portfolio updates continually reduced interest-rate risk and improved the performance in risk-on periods. The availability of these updates is a crucial differentiator from our competitors.`}</li>
      <li parentName="ul">{`With these updates in place, we believe to be well-positioned for the challenges ahead. This is especially true as we enter 2023 with much uncertainty regarding the economy's direction and inflation.`}</li>
      <li parentName="ul">{`Investors can closely replicate our backtested results in their accounts.`}</li>
    </ul>
    <p>{`In summary, we conclude that TuringTrader has proven its value in a particularly challenging year. Moreover, with the improvements we made over the year, the site is well-positioned for 2023.`}</p>
    <br />
    <ButtonPrimary text="see our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      